import { useIntl } from "react-intl";
import { observer } from "mobx-react-lite";

import { urlDecorator } from "../../utils/api";

import { availableStatuses } from "../../store/planFactory/injectPlanSerialization";

import Steps from "./steps/Steps";
import Logo from "./Logo";

import DownloadPlanButton from "./DownloadPlanButton";
import HeaderButton from "./HeaderButton";
import ImageButton from "./ImageButton";
import DownloadCsvButton from "./DownloadCsvButton";
import LockButton from "./LockButton";
import SavingInfo from "./SavingInfo";

import { ReactComponent as AvatarSVG } from "../../assets/avatar.svg";
import { ReactComponent as CheckedSVG } from "../../assets/checked.svg";
import { ReactComponent as HelperSVG } from "../../assets/helper.svg";
import { ReactComponent as SmartGardenSVG } from "../../assets/smart-garden.svg";
import useStores from "../../hooks/useStores";

let Header = () => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const { uiState } = useStores();

  const {
    planId,
    user: _user,
    plan,
    planName,
    setPlanName,
    stepIdx: currentStep,
    hasDev,
    settingsState,
    updatePlan,
    saveStatus,
    lastSaveDate,
    stepIdxByName,
    planChecked,
  } = uiState;

  const user = _user?.auth ? _user : undefined;
  const labels = settingsState.texts.header;

  const hasAdmin = user?.role === "admin";

  return (
    <header>
      <Logo
        planName={planName}
        setPlanName={plan != null ? setPlanName : undefined}
        updatePlan={updatePlan}
        formatMessage={formatMessage}
        showPhoneNumber={currentStep >= 2}
      />
      <Steps />
      <div className="right">
        {hasDev && (
          <ImageButton
            planId={planId}
            plan={plan}
            currentStep={currentStep}
            calcApi={uiState.calcApi}
          />
        )}

        {hasDev && <DownloadPlanButton plan={plan} />}

        {hasAdmin && currentStep === stepIdxByName("recommendations") && (
          <DownloadCsvButton
            plan={plan}
            planName={planName}
            settingsState={settingsState}
            pricesDictionary={plan.pricesDictionary}
          />
        )}

        {(availableStatuses.ToCheck === plan?.status ||
          availableStatuses.DuplicateToCheck === plan?.status) &&
          hasAdmin &&
          currentStep === stepIdxByName("recommendations") && (
            <HeaderButton
              onClick={planChecked}
              Icon={CheckedSVG}
              label={formatMessage({ id: labels.checkedLabel })}
            />
          )}

        {plan?.sensors.length > 0 && (
          <HeaderButton
            className="smart-garden"
            href={
              settingsState
                ? urlDecorator(
                    settingsState.links.smartGardenURL.replace(
                      ":planId",
                      planId
                    )
                  )
                : "/"
            }
            rel="noopener noreferrer"
            target="planer"
            noredirect="true"
            Icon={({ width, height }) => (
              <div className="icon" style={{ width, height }}>
                <SmartGardenSVG />
              </div>
            )}
            label={
              labels
                ? formatMessage({ id: labels.smartGardenLabel })
                : undefined
            }
          />
        )}

        {
          //IPAT-412 Implement manual locking against unintended modification
          //hasAdmin &&
          plan && (
            <LockButton
              isLocked={!plan?.editable}
              lock={async () => {
                plan.lock();
                await updatePlan();
              }}
              unlock={async () => {
                plan.unlock();
                await updatePlan();
              }}
              labels={labels}
            />
          )
        }

        <HeaderButton
          href={
            settingsState
              ? urlDecorator(
                  formatMessage({ id: settingsState.links.helperURL })
                )
              : "/"
          }
          Icon={HelperSVG}
          label={labels ? formatMessage({ id: labels.helperLabel }) : undefined}
          target="_blank"
          rel="noopener noreferrer"
        />

        <SavingInfo
          labels={labels}
          lastSaveDate={lastSaveDate}
          saveStatus={saveStatus}
        />

        <HeaderButton
          className="user"
          href={
            settingsState ? urlDecorator(settingsState.links.logoutURL) : "/"
          }
          Icon={AvatarSVG}
          label={user?.name}
        />
      </div>
    </header>
  );
};
Header = observer(Header);

export default Header;
